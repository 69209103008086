/**
 * Capitalises a string similar to css.
 * @param {string | undefined} string - The string to be capitalised.
 * @param {boolean} lower - If the string should be converted to lower case before capitalised.
 */
export const capitalize = (string, lower = false) => {
    if (!string) return '';
    if(typeof string !== 'string') return string;

    const str = lower ? string.toLowerCase() : string;

    return str.replace(/(?:^|\s|["'([{])+\S/g, (match) => match.toUpperCase());
};

export const stripHtml = (string) => {
    if (!string) return '';
    if(typeof string !== 'string') return '';

    return string.replace(/(<([^>]+)>)/gi, '');
};

export const makeSlug = (string) => string.toLowerCase().replace(/\s+/g, '-');

/**
 * Prepends a 0 to a number & formats 2 digits.
 * @param {number} number
 */
export const prependZero = (number = 0) => `0${number}`.slice(-2);

/**
 * Does what it says on the tin
 * @param {string} url
 * @returns
 */
export const replaceTrailingSlash = (url) => url.replace(/\/$/, '');

export const truncateWordsInSentence = (string, maxWords = 10) => (
    `${string.split(' ').slice(0, maxWords).join(' ')}...`
);

/**
 * Generates a random string up to 13 characters long.
 */
export const generateRandomString = (length = 8) => (
    (Math.random() + 1).toString(36).substring(2, 2 + Math.min(length, 13))
);

export const removeAllWhiteSpace = (string) => (
    string.replace(/\s+/g, '')
);
