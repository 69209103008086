import queryString from 'query-string';

import { removeAllWhiteSpace } from '../../../../helpers/text/textFormatting';

/* eslint-disable @typescript-eslint/no-explicit-any */

interface KeyValue {
    [key: string]: any;
}

/**
 * Conforms a key to a consistent format for comparison.
 */
const purifyKey = (key: string | number) => (
    removeAllWhiteSpace(key.toString().toLowerCase())
);

export const keysToSanitise = [
    'name',
    'firstname',
    'firstName',
    'middlename',
    'middleName',
    'lastname',
    'lastName',
    'age',
    'email',
    'address',
    'address1',
    'address2',
    'town',
    'city',
    'county',
    'state',
    'zip',
    'postcode',
    'contact_number',
    'contactnumber',
    'phone',
    'title',
    'salutation',
    'gender',
    'sex',
    'businessname',
    'businessName',
    'dateofbirth',
    'dob',
    'driving_number',
    'drivingnumber',
    'passport_number',
    'passportnumber',
    'passport_number1',
    'passportnumber1',
    'passport_number2',
    'passportnumber2',
    'passport_number3',
    'passportnumber3',
    'passport_number4',
    'passportnumber4',
    'passport_number5',
    'passportnumber5',
    'passport_number6',
    'passportnumber6',
    'passport_number7',
    'passportnumber7',
    'passport_number8',
    'passportnumber8',
    'country',
    'password',
    'current', // current password
    'confirm', // confirm password
    'nhs_number',
    'nshnumber',
    'NHSGPPracticeCode',
].map((key) => ( // Map through to ensure that whatever is entered above conforms to a consistent format.
    purifyKey(key)
));

class Sanitise {
    private sanitisedData: null | any = null;
    private keysToSanitise = keysToSanitise;

    constructor(data: any) {
        this.sanitisedData = this.sanitiseByType(data);
    }

    /**
     * If the data is an array object, loop through it and sanitises each item by type. Otherwise, loop
     * through the object keys and check if the key is in the list of keys to sanitise. If it is, sanitise
     * the value. Otherwise, sanitise the value by type.
     */
    sanitiseObject = (data: any[] | KeyValue) => {
        if (data === null) {
            return data;
        }

        if (Array.isArray(data)) {
            return data.map((item) => this.sanitiseByType(item));
        }

        const sanitisedData: any = {};

        Object.keys(data).forEach((key) => {
            if (this.keysToSanitise.includes(purifyKey(key))) {
                sanitisedData[key] = '*****';
                return;
            }

            sanitisedData[key] = this.sanitiseByType(data[key]);
        });

        return sanitisedData;
    };

    sanitiseUrl = (url: string) => queryString.exclude(url, this.keysToSanitise);

    /**
     * Check if a string is JSON. If it is, return the JSON string as an object. Otherwise, return null.
     */
    parseJson = (json: string) => {
        try {
            const parsedJson = JSON.parse(json);
            return parsedJson;
        } catch (error) {
            return null;
        }
    };

    /**
     * Sanitises a string. If the string is JSON, convert to an object and sanitise that, then convert back
     * to a JSON string. Otherwise, returns the original string.
     */
    sanitiseString = (string: string): any => {
        const parsedJson = this.parseJson(string);

        if (parsedJson) {
            return JSON.stringify(this.sanitiseByType(parsedJson));
        }

        if (string.startsWith('http')) {
            return this.sanitiseUrl(string);
        }

        return string;
    };

    /**
     * Check the type of the data passed in and sanitise based the resulting type. Then return the sanitised
     * data.
     */
    sanitiseByType = (data: any) => {
        let formattedData = data;

        /* eslint-disable indent */
        switch (typeof data) {
            case 'string':
                formattedData = this.sanitiseString(data);
                break;
            case 'object': // null | array | object
                formattedData = this.sanitiseObject(data);
                break;
            default: // Ignore number | undefined | boolean | function
                break;
        }
        /* eslint-enable indent */

        return formattedData;
    };

    /**
     * Returns the result of the sanitisation.
     */
    result = () => this.sanitisedData;
}

export default Sanitise;
