import { type Breadcrumb } from '@sentry/nextjs';

/**
 * Checks if a breadcrumbs url request is just a NextJs page data request.
 */
const isBreadcrumbNestJsPageDataRequest = (breadcrumb: Breadcrumb) => {
    const url = breadcrumb.data && breadcrumb.data.url ? breadcrumb.data.url : '';

    // Next.js appends _rsc to certain requests when using React Server Components.This query parameter is
    // typically used:
    // - For navigation between server-rendered pages
    // - To fetch partial updates for components without a full page reload
    // - To optimize data - fetching by running logic on the server instead of the client
    return url.includes('?_rsc=');
};

export default isBreadcrumbNestJsPageDataRequest;
