import { type Breadcrumb } from '@sentry/nextjs';

import isBreadcrumbNextJsPageDataRequest from './isBreadcrumbNextJsPageDataRequest/isBreadcrumbNextJsPageDataRequest';
import Sanitise from '../Sanitise/Sanitise';

/**
 * Checks the breadcrumb being passed in to sanitise specific breadcrumb types.
 */
const sanitiseBreadcrumb = (breadcrumb: Breadcrumb): Breadcrumb => {
    const breadcrumbClone = { ...breadcrumb };

    if ('message' in breadcrumbClone) {
        if (['sentry.event', 'sentry.transaction'].includes(breadcrumbClone.category || '')) {
            breadcrumbClone.message = new Sanitise(breadcrumbClone.message).result();
            return breadcrumbClone;
        }
    }

    if ('level' in breadcrumbClone) {
        if (breadcrumbClone.category === 'console' && ['error', 'warning'].includes(breadcrumbClone.level || '')) {
            return new Sanitise(breadcrumbClone).result();
        }
    }

    if ('type' in breadcrumbClone) {
        if (breadcrumbClone.type === 'http' && !isBreadcrumbNextJsPageDataRequest(breadcrumbClone)) {
            return new Sanitise(breadcrumbClone).result();
        }
    }

    return breadcrumbClone;
};

export default sanitiseBreadcrumb;
